<template>
  <div id="ranking-board">
    <div class="ranking-board-title">恶意域名数量地区统计</div>
    <dv-scroll-ranking-board :config="config" />
  </div>
</template>

<script>
import Vue from "vue";
import { scrollRankingBoard } from "@jiaminghi/data-view";
const components = [scrollRankingBoard];
components.forEach((component) => Vue.use(component));
export default {
  name: "RankingBoard",
  data() {
    return {
      config: {
        data: [],
        carousel: "page",
        rowNum: 10, // 前10地区
      },
    };
  },
  methods: {
    getRanking() {
      this.$http
        .get("https://www.truthahn.top:9001/API/ranking?dome=" + this.dome)
        .then((response) => {
          if (this.config.data == response.data) return;
          this.config.data = response.data;
          this.config = { ...this.config };
        });
    },
  },
  mounted() {
    this.getRanking();
    setInterval(() => {
      this.getRanking();
    }, 10000);
  },
  computed: {
    dome() {
      return this.$store.state.dome;
    },
  },
  watch: {
    dome(val, oldVal) {
      this.getRanking();
    },
  },
};
</script>

<style lang="scss" scoped>
#ranking-board {
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  box-sizing: border-box;
  padding: 0px 30px;

  .ranking-board-title {
    color: #fff;
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
}
</style>
