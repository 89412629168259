<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use, registerMap } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { MapChart } from "echarts/charts";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import chinaMap from "../assets/chinamap.json";
import worldMap from "../assets/worldmap.json";

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
  MapChart,
  CanvasRenderer,
]);

// registering map data
registerMap("china", chinaMap);
registerMap("world", worldMap);

export default {
  name: "Map",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    let self = this;
    return {
      option: {
        backgroundColor: "", //背景透明
        title: {
          text: "恶意域名数量地区统计",
          left: "center",
        },
        visualMap: {
          left: "right",
          min: 50,
          max: 8800,
          inRange: {
            color: ["lightskyblue", "yellow", "orangered"],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            const value = (params.value + "").split(".");
            const valueStr = value[0].replace(
              /(\d{1,3})(?=(?:\d{3})+(?!\d))/g,
              "$1,"
            );
            return params.name + ": " + valueStr;
          },
        },
        toolbox: {
          left: "left",
          feature: {
            myTool1: {
              show: true,
              title: "切换",
              icon: "M575.914667 725.333333a21.397333 21.397333 0 0 1-21.248-21.162666V319.829333A21.184 21.184 0 0 1 576 298.666667c11.776 0 21.333333 9.706667 21.333333 21.162666v333.909334l85.568-85.568a21.226667 21.226667 0 0 1 30.101334 0.064c8.32 8.32 8.213333 21.973333 0.085333 30.101333l-120.832 120.810667a21.141333 21.141333 0 0 1-16.341333 6.186666z m-152.789334-426.325333a21.418667 21.418667 0 0 1 24.896 20.864V704.213333a21.205333 21.205333 0 0 1-21.333333 21.162667c-11.797333 0-21.354667-9.706667-21.354667-21.162667V364.266667l-91.669333 91.605333a21.248 21.248 0 0 1-30.122667-0.064 21.418667 21.418667 0 0 1-0.064-30.101333l120.896-120.810667a21.184 21.184 0 0 1 18.752-5.888z m252.202667-181.290667A425.429333 425.429333 0 0 0 512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667c0-56.746667-11.093333-112-32.384-163.328a21.333333 21.333333 0 0 0-39.402667 16.341333A382.762667 382.762667 0 0 1 896 512c0 212.074667-171.925333 384-384 384S128 724.074667 128 512 299.925333 128 512 128c51.114667 0 100.8 9.984 146.986667 29.12a21.333333 21.333333 0 0 0 16.341333-39.402667z",
              onclick: function () {
                self.changeMap();
              },
            },
          },
        },
        series: [
          {
            name: "地区统计",
            type: "map",
            roam: true,
            map: "world",
            center: null,
            zoom: 1,
            data: [],
            emphasis: {
              itemStyle: {
                //选中样式
                borderWidth: 2,
                borderColor: "#fffaf0",
                areaColor: "#7fff00",
              },
              label: {
                show: true,
                color: "#778899",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    getCount() {
      var id = this.dome;
      this.getMax(id);
      this.$http
        .get("https://truthahn.top:9001/api/getcountry?dome=" + id)
        .then((response) => {
          //清空
          this.option["series"][0]["data"] = [];
          //
          response.data.forEach((element) => {
            this.option["series"][0]["data"].push({
              name: element["name"],
              value: element["value"],
            });
          });
        });
    },
    getMax(id) {
      this.$http
        .get("https://truthahn.top:9001/api/getcountrymax?dome=" + id)
        .then((response) => {
          this.option["visualMap"]["min"] = response.data["min"];
          this.option["visualMap"]["max"] = response.data["max"];
        });
    },
    changeMap() {
      this.$store.commit("changedome");
    },
  },
  mounted() {
    this.getCount();
  },
  computed: {
    dome() {
      return this.$store.state.dome;
    },
  },
  watch: {
    dome(val, oldVal) {
      if (this.dome == 0) this.option["series"][0]["map"] = "world";
      else this.option["series"][0]["map"] = "china";
      this.getCount();
    },
  },
};
</script>