<template>
  <div id="digital-flop">
    <div
      class="digital-flop-item"
      v-for="item in digitalFlopData"
      :key="item.title"
    >
      <div class="digital-flop-title">{{ item.title }}</div>
      <div class="digital-flop">
        <dv-digital-flop
          :config="item.number"
          style="width: 100px; height: 50px"
        />
        <div class="unit">{{ item.unit }}</div>
      </div>
    </div>

    <dv-decoration-10 />
  </div>
</template>

<script>
import Vue from "vue";
import { decoration10, digitalFlop } from "@jiaminghi/data-view";
const components = [decoration10, digitalFlop];
components.forEach((component) => Vue.use(component));
export default {
  name: "DigitalFlop",
  data() {
    return {
      digitalFlopData: [],
    };
  },
  methods: {
    getDoaminCount() {
      var data = [
        {
          title: "域名总数",
          number: {
            number: [],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "正常域名",
          number: {
            number: [],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#f46827",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "恶意域名",
          number: {
            number: [],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#40faee",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "博彩网站",
          number: {
            number: [],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "色情网站",
          number: {
            number: [],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#f46827",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
      ];
      this.$http
        .get("https://www.truthahn.top:9001/API/digitalflop")
        .then((response) => {
          data[0]["number"]["number"] = [parseInt(response.data[0]["total"])];
          data[1]["number"]["number"] = [
            parseInt(response.data[0]["total"]) -
              parseInt(response.data[0]["gamble"]) -
              parseInt(response.data[0]["sex"]),
          ];
          data[2]["number"]["number"] = [
            parseInt(response.data[0]["gamble"]) +
              parseInt(response.data[0]["sex"]),
          ];
          data[3]["number"]["number"] = [parseInt(response.data[0]["gamble"])];
          data[4]["number"]["number"] = [parseInt(response.data[0]["sex"])];
          this.digitalFlopData = data;
        });
    },
  },
  mounted() {
    this.getDoaminCount();
    setInterval(() => {
      this.getDoaminCount();
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
#digital-flop {
  color: #fff;
  position: relative;
  height: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 95%;
    left: 2.5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 11%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
}
</style>