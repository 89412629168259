<template>
  <div>
    <div id="bg">
      <div id="top-header">
        <dv-decoration-8 class="header-left-decoration" />
        <dv-decoration-5 class="header-center-decoration" />
        <dv-decoration-8 class="header-right-decoration" :reverse="true" />
        <div class="center-title">监测平台</div>
      </div>
      <digital-flop />
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <ranking-board class="tile is-child" />
        </div>
        <div class="tile is-parent">
          <Map />
        </div>
        <div class="tile is-parent">
          <scroll-board class="tile is-child" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { decoration5, decoration8 } from "@jiaminghi/data-view";
const components = [decoration5, decoration8];
components.forEach((component) => Vue.use(component));
import DigitalFlop from "@/components/digitalFlop.vue";
import RankingBoard from "@/components/rankingBoard.vue";
import ScrollBoard from "@/components/scrollBoard.vue";
import Map from "@/components/map.vue";
export default {
  name: "Index",
  components: {
    DigitalFlop,
    RankingBoard,
    ScrollBoard,
    Map,
  },
};
</script>

<style lang="scss" scoped>
#bg {
  background-image: url("~@/assets/img/bg.png");
  background-size: 100% 100%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  height: 1000px;
}
#top-header {
  color: #fff;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
  }
  .header-left-decoration,
  .header-right-decoration {
    width: 25%;
    height: 60px;
  }
  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
  }
}
</style>