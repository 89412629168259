<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
import Vue from "vue";
import { scrollBoard } from "@jiaminghi/data-view";
const components = [scrollBoard];
components.forEach((component) => Vue.use(component));
export default {
  name: "ScrollBoard",
  data() {
    return {
      config: {
        header: ["时间", "域名", "种类"],
        data: [],
        index: true,
        columnWidth: [50, 150, 150],
        align: ["center"],
        rowNum: 7,
        headerBGC: "#1981f6",
        headerHeight: 45,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },
  methods: {
    getScrollBoard() {
      this.$http
        .get("https://www.truthahn.top:9001/API/scrollboard")
        .then((response) => {
          var a = new Array();
          for (var i = 0; i < 100; i++) {
            //一维长度为返回域名数量
            a[i] = new Array();
            for (var j = 0; j < 3; j++) {
              //二维长度为5
              a[i][j] = i + j;
            }
          }
          if (response.data.length == this.config.data.length) return;
          for (var i = 0; i < response.data.length; i++) {
            a[i][0] = response.data[i]["time_update"];
            a[i][1] = response.data[i]["url_netloc"];
            a[i][2] = response.data[i]["family"];
            this.config.data[i] = a[i];
          }
          this.config = { ...this.config };
        });
    },
  },
  mounted() {
    this.getScrollBoard();
    setInterval(() => {
      this.getScrollBoard();
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
#scroll-board {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}
</style>
